<script setup lang="ts">
const layoutStore = useLayoutStore()

onMounted(() => {
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    layoutStore.updateTouchDevice(true)
  }
  updateResponsive()
  window.addEventListener('resize', updateResponsive)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResponsive)
})

const updateResponsive = () => {
  const responsive = useGetResponsive()
  layoutStore.updateResponsive(responsive)
}
</script>

<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //width: 100%;
  //height: 100%;
  background-color: $color-neutral-light-4;

  // breakpoint: tablet
  @include tablet {
  }

  // breakpoint: mobile
  @include mobile {
  }
}
</style>
